import { ChainId, Token } from '@abstra-dex/sdk'
import { serializeToken } from 'state/user/hooks/helpers'
import { CHAIN_ID } from './networks'
import { SerializedToken } from './types'
import addresses from './addresses.json'

const { MAINNET, TESTNET } = ChainId

interface TokenList {
  [symbol: string]: Token
}

const defineTokens = <T extends TokenList>(t: T) => t

export const mainnetTokens = {
  eth: new Token(MAINNET, addresses[MAINNET].WETH, 18, 'OKB', 'X Layer OKB', 'https://abstradex.xyz/'),
  cake: new Token(MAINNET, addresses[MAINNET].PlatformToken, 18, 'ABS', 'AbstraDEX', 'https://abstradex.xyz/'),
  // usdt: new Token(
  //   MAINNET,
  //   '0x01445C31581c354b7338AC35693AB2001B50b9aE',
  //   6,
  //   'USDT',
  //   'Multichain Tether USD',
  //   'https://wagyuswap.app/',
  // ),
  weth: new Token(MAINNET, addresses[MAINNET].WETH, 18, 'WOKB', 'Wrapped OKB', 'https://abstradex.xyz/'),
  weth2: new Token(
    MAINNET,
    '0x5a77f1443d16ee5761d310e38b62f77f726bc71c',
    18,
    'WETH',
    'Wrapped ETH',
    'https://abstradex.xyz/',
  ),
  okbaby: new Token(
    MAINNET,
    '0xd0034aee8e1d8d8919a401c546ed2d0f2a4c90f1',
    18,
    'OKBABY',
    'OKB BABY',
    'https://abstradex.xyz/',
  ),
  okcat: new Token(
    MAINNET,
    '0x5574730e54e167e2fd0003aed0d6ce31ed8b4600',
    18,
    'OKcat',
    'OKcat',
    'https://abstradex.xyz/',
  ),
  busd: new Token(
    MAINNET,
    '0xf58ae68f5f295f4aa4a9d56065efaa5197f2baa9',
    18,
    'USDT',
    'Tether USDT',
    'https://abstradex.xyz/',
  ),
  usdc: new Token(
    MAINNET,
    '0x1e4a5963abfd975d8c9021ce480b42188849d41d',
    6,
    'USDT',
    'USD Tether',
    'https://abstradex.xyz/',
  ),
  usdt: new Token(
    MAINNET,
    '0x1e4a5963abfd975d8c9021ce480b42188849d41d',
    6,
    'USDT',
    'USD Tether',
    'https://abstradex.xyz/',
  ),
  // avax: new Token(
  //   MAINNET,
  //   '0x525bd1f949ffa2a0c5820f3b6fe61bb897466ff7',
  //   18,
  //   'AVAX',
  //   'Velas AVAX',
  //   'https://wagyuswap.app/',
  // ),
  // ftm: new Token(
  //   MAINNET,
  //   '0xc9b3aa6e91d70f4ca0988d643ca2bb93851f3de4',
  //   18,
  //   'FTM',
  //   'Velas FTM',
  //   'https://wagyuswap.app/',
  // ),
  // matic: new Token(
  //   MAINNET,
  //   '0x6ab0b8c1a35f9f4ce107ccbd05049cb1dbd99ec5',
  //   18,
  //   'MATIC',
  //   'Velas Polygon',
  //   'https://wagyuswap.app/',
  // ),
  // bitorb: new Token(
  //   MAINNET,
  //   '0x09bce7716d46459df7473982fd27a96eabd6ee4d',
  //   6,
  //   'BITORB',
  //   'Bitorbit',
  //   'https://bitorbit.com',
  // ),
  // scar: new Token(
  //   MAINNET,
  //   '0x8d9fb713587174ee97e91866050c383b5cee6209',
  //   18,
  //   'SCAR',
  //   'Velhalla',
  //   'https://velhalla.io/',
  // ),
  // astro: new Token(
  //   MAINNET,
  //   '0x72eb7ca07399ec402c5b7aa6a65752b6a1dc0c27',
  //   18,
  //   'ASTRO',
  //   'AstroSwap',
  //   'https://www.astroswap.app/',
  // ),
  // swapz: new Token(
  //   MAINNET,
  //   '0x9b6fbF0ea23faF0d77B94d5699B44062e5E747Ac',
  //   18,
  //   'SWAPZ',
  //   'SWAPZ.app',
  //   'https://swapz.app',
  // ),
  // usdv: new Token(
  //   MAINNET,
  //   '0xcd7509b76281223f5b7d3ad5d47f8d7aa5c2b9bf',
  //   18,
  //   'USDV',
  //   'USD Velero Stablecoin',
  //   'https://velero.finance/',
  // ),
  // vdgt: new Token(
  //   MAINNET,
  //   '0xd12f7a98c0d740e7ec82e8caf94eb79c56d1b623',
  //   18,
  //   'VDGT',
  //   'VELERO',
  //   'https://velero.finance/',
  // ),
  // dai: new Token(MAINNET, '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d', 18, 'DAI', 'dai', 'https://makerdao.com/'),
  // wbtc: new Token(
  //   MAINNET,
  //   '0x639a647fbe20b6c8ac19e48e2de44ea792c62c5c',
  //   18,
  //   'WBTC',
  //   'Wrapped Bitcoin',
  //   'https://wbtc.network/',
  // ),
  // vlxpad: new Token(
  //   MAINNET,
  //   '0xa065e0858417dfc7abc6f2bd4d0185332475c180',
  //   18,
  //   'VLXPAD',
  //   'Velas Pad',
  //   'https://velaspad.io/',
  // ),
  syrup: new Token(
    MAINNET,
    '0xa8d9b7a299248fa20f1c88ca385ba1908cf31ee8',
    18,
    'ABS',
    'AbstraDEX',
    'https://abstradex.xyz/',
  ),
  // te6: new Token(TESTNET, addresses[111].TE6, 6, 'TE6', 'ERC20 Token', 'https://wagyuswap.app/'),
  // te9: new Token(TESTNET, addresses[111].TE9, 9, 'TE9', 'ERC20 Token', 'https://wagyuswap.app/'),
  // te12: new Token(TESTNET, addresses[111].TE12, 12, 'TE12', 'ERC20 Token', 'https://wagyuswap.app/'),
  // te18: new Token(TESTNET, addresses[111].TE18, 18, 'TE18', 'ERC20 Token', 'https://wagyuswap.app/'),
}

export const testnetTokens = {
  cake: new Token(TESTNET, addresses[TESTNET].PlatformToken, 18, 'ABS', 'AbstraDEX', 'https://abstradex.xyz/'),
  weth: new Token(TESTNET, addresses[TESTNET].WETH, 18, 'WAZERO', 'Wrapped AZERO', 'https://abstradex.xyz/'),
  busd: new Token(
    TESTNET,
    '0xd0034aee8e1d8d8919a401c546ed2d0f2a4c90f1',
    18,
    'USDC',
    'USD coin',
    'https://abstradex.xyz/',
  ),
  usdc: new Token(
    TESTNET,
    '0xd0034aEe8e1d8d8919A401c546Ed2d0F2A4C90F1',
    18,
    'USDC',
    'USD coin',
    'https://abstradex.xyz/',
  ),
  okcat: new Token(
    TESTNET,
    '0xd0034aEe8e1d8d8919A401c546Ed2d0F2A4C90F1',
    18,
    'USDC',
    'USD coin',
    'https://abstradex.xyz/',
  ),
  usdt: new Token(
    TESTNET,
    '0xd0034aEe8e1d8d8919A401c546Ed2d0F2A4C90F1',
    18,
    'USDT',
    'USD Tether',
    'https://abstradex.xyz/',
  ),
}

const tokens = () => {
  const chainId = CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(testnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] }
    }, {} as typeof testnetTokens)
    // return Object.keys(mainnetTokens).reduce((accum, key) => {
    //   return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    // }, {} as typeof testnetTokens & typeof mainnetTokens)
  }

  return mainnetTokens
}

const unserializedTokens = tokens()

type SerializedTokenList = Record<keyof typeof unserializedTokens, SerializedToken>

export const serializeTokens = () => {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {} as SerializedTokenList)

  return serializedTokens as any
}

export default unserializedTokens
